(() => {

  window.addEventListener('DOMContentLoaded', () => {
    let timeout = setTimeout(() => {
      window.location.href = 'https://ashevilleadventurecompany.com/';
      window.clearTimeout(timeout)
    }, 5000)
  })

}) ()
